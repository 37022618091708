.stripe-footer {
  background-color: var(--black-alt);
}

.footer {
  padding: 1rem;
  color: var(--white);
  font-family: Josefin Sans, sans-serif;
}

.footer-section {
  margin-bottom: 1rem;
}

.footer-section h4 {

  font-size: 1.25rem;
  font-weight: 500;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: var(--white);
  font-size: 1rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: var(--accent);
}

.footer-nav-link-wrapper {
  list-style: none;
  padding: 0;
}

.footer-nav-link {
  font-family: Josefin Sans, sans-serif;
  color: var(--white);
  font-size: 1rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-nav-link:hover {
  color: var(--accent);
}

.social-link {
  font-size: 1.5rem;
}

.usa-container {
  text-align: center;
  margin-top: 1rem;
}

.made-in-usa p {
  font-size: 1rem;
  line-height: 1.5;
}

.usa-flag {
  width: 50px;
  height: auto;
}

.footer-bottom {
  text-align: center;
  margin-top: 1rem;
  border-top: 1px solid var(--white);
  padding-top: 1rem;
}

.footer-bottom p {
  margin: 0.5rem 0;
}

/* Mobile */
@media (max-width: 767px) {
  .footer-section {
    text-align: center;
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 992px) {
  .footer-section {
    text-align: left;
  }
}

/* Laptop */
@media (min-width: 993px) and (max-width: 1312px) {
  .footer-section {
    text-align: left;
  }
}

/* Desktop */
@media (min-width: 1313px) {
  .footer-section {
    text-align: left;
  }
}

/* Ultrawide */
@media (min-width: 1920px) {
  .footer-section {
    text-align: left;
  }
}
