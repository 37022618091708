.navbar .dropdown.show a.dropdown-toggle {
  color: gold !important;
}

.navbar a {
  color: var(--white);
  text-decoration: none;
}

.navbar a:hover, 
.navbar a:focus, 
.navbar a:active {
  color: gold;
}

.products-navbar {
  margin-top: 1rem;
  background-color: var(--grey) !important;
  color: var(--white);
  border-radius: 5px;
  padding: 0.5rem;
  max-width: 27rem;
  margin-left: auto;
  margin-right: auto;
  font-family: Josefin Sans;
  font-weight: 700;
  font-size: 1.2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}


.products-navbar .nav-link,
.products-navbar.dropdown {
  width: auto;
}

.products-navbar .nav-link {
  align-items: center;
  font-family: Josefin Sans;
  margin-right: 2rem;
}

.dropdown-menu {
  background-color: var(--black) !important;
  font-family: Josefin Sans;
  border-radius: 5px;
}

/* Reset Filters Button styles */
.reset-filters {
  display: block;
  margin-left: auto;
  border-radius: 5px;
  font-family: Josefin Sans;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/*media query to ensure nav bar is wider for mobile devices*/
@media (min-width:1000px) and( max-width: 1081px) {
  .products-navbar {
    min-width: 50rem!important;
  }
}
