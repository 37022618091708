/*TRIO OF HIGHLIGHTS*/
.stripe-trio {
  position: relative;
}

.stripe-trio:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 200vw;
  margin-left: -50vw;
  z-index: -1;
  background-color: var(--black);
}

.hilightContainer {
  background-color: var(--black);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding-left: 4rem;
  padding-top: 2rem;
}

.hilightCard {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: 5rem;
}

.hexagon-container {
  position: relative;
  width: 60px;
  height: 56.6px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(90deg);
  margin-right: 0.25rem;
}

.hexagon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.hilight-text {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.2rem;
  color: var(--white);
  font-weight: 300;
}

/* Mobile */
@media (max-width: 440px) {
  .hilightCard {
    display: inline-flex;
    margin-bottom: 3rem;
    flex-direction: row;
  }

  .hilightContainer {
    padding-left: auto; /* Remove the left padding */
    padding-top: 2rem;
  }

  .hexagon-container {
    width: 60px;
    height: 50px;
  }

  .hexagon-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg) scale(0.75);
  }

  .hilight-text {
    font-size: 1.5rem;
  }

}

@media (min-width: 441px) and (max-width: 767px) {
  .hilightCard {
    align-items: center; 
    justify-content: center; 
    display: inline-flex;
    margin-bottom: 3rem;
    flex-direction: row;
    margin-right: 2rem;
    
  }

  .hilightContainer {
    background-color: var(--black);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    
  }

  .hexagon-container {
    width: 60px;
    height: 50px;
  }

  .hexagon-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg) scale(0.75);
  }

  .hilight-text {
    font-size: 1.5rem;
    text-align: center;

    
  }


}

/* Tablet */
@media (min-width: 768px) and (max-width: 992px) {
  .hilightContainer {
    margin-left: 1rem;
  }
  .hilightCard {
    display: flex;
    align-items: center; 
    justify-content: center; 
    margin-bottom: 3rem;
    flex-direction: row;
    margin-right: 2rem; 
  }

  .hexagon-container {
    width: 60px;
    height: 56.6px;
    margin-right: 0.25rem;
  }

  .hexagon-inner {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  .hilight-text {
    text-align: center;
    font-size: 1.5rem;
  }

  .hilightContainer {
    padding-left: 0;
    padding-top: 2rem;
  }
}


/* Laptop + Desktop */
@media (min-width: 993px) and (max-width: 1312px) {
  .hilightCard {
    display: flex;
    align-items: center; /* Center align items vertically */
    justify-content: center; /* Center align items horizontally */
    margin-bottom: 3rem;
    flex-direction: row;
    margin-right: 2rem; 
  }

  .hilight-text {
    text-align: center;
    font-size: 1.5rem;
  }

}

/* Ultrawide */
@media (min-width: 1312px) {
  .hilight-text {
    font-size: 1.4rem;
  }

  .hilightCard {
    display: flex;
    align-items: center; 
    justify-content: center; 
    margin-bottom: 3rem;
    flex-direction: row;
    margin-right: 3rem; 
  }
}
