.artist-page {
  margin-bottom: 2rem;
  font-family: Josefin Sans, sans-serif;
}

.artist-title-header {
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: -1.5px;
  text-align: center;
  margin-top: 1rem;
}

.artist-card {
  width: 250px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
  background-color: #6c757d!important;
  padding: 0.5rem;
  margin: 0 auto;
}

.artist-card .artist-name {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1.5px;
  text-align: center;
}

.artist-card .mouthpiece {
  padding: 0.5rem;
  background-color: rgb(47, 40, 40);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  letter-spacing: -1.5px;
  width: 11.75rem;
}

.artist-chevron {
  color: #2f2828;
  background: none;
  border: none;
  width: 2rem;
  height: 2rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -.5rem;
  font-size: 1.5rem;
  z-index: 2;
}

.pagination-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.mouthpiece-container {
  max-height: 100px;
  overflow-y: auto;
}

.mouthpiece-container::-webkit-scrollbar {
  display: none;
}

.music-note {
  font-size: 3rem;
  animation: spin 2s linear infinite;
  margin-top: -1.25rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 100%);
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(1turn);
  }
}

