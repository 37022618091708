@import url("/src/styles/theme.css");

/*PRODUCTSPAGE.JS*/

.products-grid {
  display: flex;
  justify-content: center;
  position: relative;
  opacity: 1; 
}

.product-row {
  display: flex;
  justify-content: center;
  row-gap: 2rem;
  flex-wrap: wrap;
}

.category-title {
  font-family: Josefin Sans;
  font-size: 3rem;
  font-weight: 700;
  margin: 5% 0 1rem;
  text-align: center;
  color: black;
}


/* PRODUCTCARD.JS */
.product-card-container {
  display: flex;
  flex-direction: column;
  font-family: Josefin Sans;
}

.product-card {
  border: 0;
}

/*PRODUCTCARDSPECS.JS*/
.product-image img {
  height: 20rem;
  width: 100%;
  object-fit: scale-down;
}

.product-info-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product-card-description {
  border-color: transparent;
  min-height: 11rem;
  padding: 1rem;
  border-radius: 0;
}

.product-icon-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.25rem;
}

.product-id {
  background-color: rgba(229, 172, 0, 0.8);
  padding: 5px;
  border-radius: 3px;
  font-weight: bold;
  min-width: 2rem;
  margin-right: -1rem;
}

.bore-section {
  background-color: #bbabef;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  max-width: 150px;
}

.accessories {
  background-image: var(--accent);
  border-radius: 0.5rem;
  color: black;
  font-size: 1rem;
  letter-spacing: -1px;
  max-width: 11rem;
  height: 2rem;
  padding-bottom: -1.5rem;
  padding: 0.1rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
}

.model {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center content horizontally */
  text-align: center;
  background-color: black;
  color: white;
  font-weight: 300;
  padding: 0.25rem 0.5rem; /* Reduce vertical padding */
  max-width: 6.5rem;
  letter-spacing: -1px;
  font-size: 1.25rem;
  line-height: 1.25rem;
  border-radius: 0.5rem;
}

.model > p {
  margin: 0; /* Remove default margin */
}


.ligature-type {
  text-align: center;
  max-width: 7.2rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  background: linear-gradient(to right, #7d878d, #2c3e50);
  color: white;
  font-weight: 300;
  letter-spacing: -1px;
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.ligature-type p{
  text-align: center! important;
  align-items: center;
  color: white;
  font-weight: 300;
  letter-spacing: -1px;
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-bottom: -.25rem;
}

.finish {
  text-align: center;
  display: inline-flex; 
  flex-wrap: wrap;
  align-items: center; 
  justify-content: center; 
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  background: linear-gradient(to right, #dfe2e0, #f0ee7c);
  color: black;
  font-weight: 300;
  letter-spacing: -1px;
  font-size: 1.25rem;
  line-height: 1.25rem; 
  max-width: 8rem;
}

.finish > p {
  margin: 0;
  word-wrap: break-word;
}

.custom-bubble {
  background-color: #f39e00;
  color: #ffffff;
  font-weight: 300;
  width: 40%;
  height: 2rem;
  padding: 0.125rem;
  margin-top: 0.5rem;
  border-radius: .5rem;
  font-size: 1.25rem;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); 
  margin-left: auto;
  margin-right: auto;
}

/*arrow for cursor on hover not cursor or pointer*/
.custom-bubble:hover {
  -webkit-animation: jello-vertical .4s cubic-bezier(.455,.03,.515,.955) both;
  animation: jello-vertical .9s cubic-bezier(.455,.03,.515,.955) both;
  cursor: auto;
}


.price {
  font-size: 1.25rem;
  text-align: center !important;
  color: var(--black);
  font-weight: 300;
}

.find-dealer  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;  
}

.product-contact-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;  
  font-family: Josefin Sans;
  font-weight: 700;
}

.product-email-signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: var(--black);
  text-align: center;
}

.find-dealer {
  margin-left: auto!important;
  margin-right: auto!important;
  display: block;
  margin-left: auto;
  border-radius: 5px;
  font-family: Josefin Sans;
  font-weight: 700;
  font-size: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: -2rem;
  background: linear-gradient(90deg, #FFA500, #FFD700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.email-signup-form {
  background-color: var(--black-alt);
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
}

.email-signup-form h2 {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--white);
}

.email-signup-form label {
  font-weight: 100;
  font-size: 1.2rem;
  display: block;
  margin-bottom: 0.5rem;
  color: var(--white);
}

.email-signup-form input {
  width: 100%;
  padding: 0.5rem;
  font-weight: 100;
  font-size: 1rem;
  border: 1px solid var(--white);
  background-color: var(--grey-alt);
  color: var(--white);
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.email-signup-form button {
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  background: var(--accent);
  color: var(--white);
  border-radius: 0.25rem;
  transition: 0.3s;
}


.email-signup-success {
  background-color: var(--black-alt);
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
  text-align: center;
}

.email-signup-success p {
  font-weight: 100;
  font-size: 1.2rem;
  color: var(--white);
}

div.col-xl-8.col-lg-8.col-md-12.col-sm-12 > div > div:nth-child(2) > div:nth-child(5) > div > div > div.product-image > img {
  width: 100%!important;
  height: 25rem!important;
  margin-top: -4.9rem;
}

/* Shake Horizontal Animation */
.jello-vertical{-webkit-animation:jello-vertical .9s both;animation:jello-vertical .9s both}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-22 1:6:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

 @-webkit-keyframes jello-vertical{0%{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}30%{-webkit-transform:scale3d(.75,1.25,1);transform:scale3d(.75,1.25,1)}40%{-webkit-transform:scale3d(1.25,.75,1);transform:scale3d(1.25,.75,1)}50%{-webkit-transform:scale3d(.85,1.15,1);transform:scale3d(.85,1.15,1)}65%{-webkit-transform:scale3d(1.05,.95,1);transform:scale3d(1.05,.95,1)}75%{-webkit-transform:scale3d(.95,1.05,1);transform:scale3d(.95,1.05,1)}100%{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}}@keyframes jello-vertical{0%{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}30%{-webkit-transform:scale3d(.75,1.25,1);transform:scale3d(.75,1.25,1)}40%{-webkit-transform:scale3d(1.25,.75,1);transform:scale3d(1.25,.75,1)}50%{-webkit-transform:scale3d(.85,1.15,1);transform:scale3d(.85,1.15,1)}65%{-webkit-transform:scale3d(1.05,.95,1);transform:scale3d(1.05,.95,1)}75%{-webkit-transform:scale3d(.95,1.05,1);transform:scale3d(.95,1.05,1)}100%{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}}