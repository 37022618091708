.tip-opening-carousel {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  gap: 1rem;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  margin-bottom: 1rem;
  align-items: center;
  width: 130%;
  margin-left: -15%;
}

.tip-opening-carousel::-webkit-scrollbar {
  display: none;
}

.tip-opening-carousel-item {
  flex: 0 0 auto;
}

.tip-opening-carousel-image {
  width: 300px;
  height: 400px;
  object-fit: cover;
  transition: transform 0.5s;
  display: block;
  border-radius: 0.5rem !important;
}

.tip-opening-carousel-item carousel-item-10 {
  border-radius: 0.5rem !important;
}
