/* Dealers page */
.dealers-page {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Josefin Sans", sans-serif;
}

.dealer-map-title {
  font-family: "Josefin Sans", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: -3.5px;
  margin-top: 1rem;
}

/* Map container */
.leaflet-container {
  border-radius: 10px;
  padding: 10px;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

/* Search container */
.dealer-search-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  max-height: calc(30vh - 0.5rem);
  overflow-y: auto;
  margin-bottom: 1rem;
  z-index: 1000;
  font-family: Josefin Sans, sans-serif;
  margin-right: 1rem;
}

/* Search bar */
.dealer-search-bar {
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  z-index: 1001;
  overflow: hidden;
  height: 2.4rem;
  padding: 0;
}

.dealer-search-input::placeholder {
  color: var(--grey);
  font-style: italic;
}

.dealer-info-container {
  display: flex;
  flex-direction: column;
  z-index: 0;
  font-size: 1rem;
  max-height: calc(80vh - 50px);
  overflow-y: auto;
  padding: 0.5rem;
}

.dealer-list-container {
  min-height: 200px;
  top: 150px;
}

div .no-results-placeholder {
  padding: 10px;
  color: #666;
  font-style: italic;
  font-size: 2rem;
}

.musical-note-icon {
  color: var(--nav-accent);
  font-size: 1.75rem;
}

.leaflet-popup-content-wrapper {
  background-color: var(--white);
  border-radius: 5px;
}

.leaflet-popup-tip-container {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10000;
}

.leaflet-popup-tip {
  background-color: var(--white);
  border: 2px solid var(--grey-alt);
  box-shadow: 0 0 1px var(--grey-alt);
}

.dealer-map-popup .leaflet-popup-content-wrapper {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 1rem;
}

.dealer-map-popup .leaflet-popup-content {
  margin: 0;
  min-width: min-content;
}

.dealer-popup-icon {
  color: darkblue;
  font-size: 1rem;
  border: none;
  margin-right: 10px;
}

.popup-content {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.25rem;
}

.popup-detail {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.popup-detail i {
  margin-right: 15px;
  color: var(--nav-accent);
}

.dealer-search-input {
  cursor: pointer;
}
.dealer-info-container {
  cursor: pointer;
}

.leaflet-grab {
  cursor: grab;
}

.leaflet-grabbing {
  cursor: grabbing;
}

#search-addon.input-group-text {
  cursor: pointer;
}

.dealer-info-container:hover {
  background-color: #f0f0f0;
}

.dealer-search-container,
.dealer-info-container {
  touch-action: auto;
}


@media (max-width: 500px) {
  .dealer-search-container {
    width: 100%;
    left: 0;
    margin-bottom: -1rem;
    max-height: 45vh;
  }

  .dealer-list-container {
    min-height: 200px;
    top: 150px;
    overflow-y: scroll !important;
  }

  .dealer-list-container::-webkit-scrollbar {
    display: none;
  }
  .dealer-list-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media (max-width: 768px) {

  .dealer-search-container {
    width: 100%;
    left: 0;
    transform: none;
    margin-bottom: -1rem;
    max-height: 45vh;
  }
  .dealer-info-container {
    overflow-y: auto;
  }

  .dealer-map-title {
    font-size: 3rem;
  }

  .dealer-search-input {
    cursor: pointer;
  }

  .dealer-map-popup .leaflet-popup-content-wrapper {
    padding: 0.5rem;
  }

  .dealer-map-popup .leaflet-popup-content {
    font-size: 1rem;
  }

  .popup-detail {
    margin: 2px 0;
  }

  .popup-detail i {
    margin-right: 10px;
  }

  .popup-content {
    font-size: 1rem;
  }
}
