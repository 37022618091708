.home-dealer-stats {
  margin-top: 10rem;
  margin-bottom: -2rem;
  gap: 2rem;
}

.stats-card {
  background-color: #ffffff;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 13rem;
  height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease;
  margin-bottom: 1.5rem;
}

.stats-card:hover {
  box-shadow: 0px 0px 20px 2px rgba(255, 223, 0, 0.7);
}

.gradient-text {
  background: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: -1rem;
}

.stats-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0c0b0b;
  margin-top: 0.25rem;
}

.stats-icon {
  width: auto;
  height: 4rem;
  margin-top: -0.5rem;
  margin-bottom: -0.25rem;
}

/*media query to make the stats cards smaller on mobile*/

@media screen and (max-width: 480px) {
  .home-dealer-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    gap: 0rem;

  }

  .stats-card {
    width: 6rem!important;
    height: 6rem!important;
  }


  .gradient-text {
    font-size: 1.75rem!important;
  }

  .stats-title {
    font-size: .75rem!important;

  }

  .stats-icon {
    height: 1.5rem!important;
    margin-top: .5rem;
  }
}

@media (max-width: 768px) {
  
  .home-dealer-stats {
    gap: -1rem;
  }

  .stats-card {
    width: 10rem;
    height: 10rem;
  }

  .gradient-text {
    font-size: 4rem;
  }

  .stats-title {
    font-size: 1.2rem;
  }

  .stats-icon {
    height: 3rem;
  }
}
