.slick-slider div{
  display: flex;
  align-items: center;
  justify-content: center;

}

.instagram-carousel .slick-list .slick-track .slick-slide .ig-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:.5rem;
}

.instagram-carousel {
  margin: 0 -4.5rem;  
}


.ig-slide img {
  width: 100%;
  border-radius: 2rem;
  
}





@media (max-width: 440px) {
  .instagram-carousel {
    margin: 0 -20px;
  }

}

@media (min-width: 441px) and (max-width: 767px) {
  .instagram-carousel {
    margin: 0 -6rem;
  }

}

@media (min-width: 768px) and (max-width: 992px) {
  .instagram-carousel {
    margin: 0 -8rem;
  }


}

@media (min-width: 993px) and (max-width: 1312px) {

}






