@import url("/src/styles/theme.css");



.stripe-carousel {
  position: relative;
}

.stripe-carousel:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 200vw;
  left: -100vw;
  z-index: -1;
  background-color: var(--black);
}

.carousel-fade {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  pointer-events: none;
  z-index: 2;
  background-image: linear-gradient(
    to right,
    rgba(12, 11, 11, 0.8),
    rgba(12, 11, 11, 0)
  );
}

.carousel-fade-right {
  right: 0;
  transform: scaleX(-1);
}

.home-top-carousel{
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 1rem;
  background-color: var(--black)!important;
}

.home-top-carousel-slide {
  border-radius: 1rem;
  background-color: var(--black)!important;
  padding: .25rem;
}


.home-top-carousel-image {
  object-fit: cover;
  border-radius: 1rem;
}


li.dot{
  display:none!important;
}

