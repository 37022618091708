.stripe-categories {
  position: relative;
}

.stripe-categories:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 200vw;
  left: -100vw;
  z-index: -1;
  background-color: var(--black);
}

.home-categories {
  background-color: whitesmoke;
  font-family: "Josefin Sans", sans-serif;
  color: var(--black);
  border-radius: 0.5rem;

}

.home-categories h2 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 5rem;
  font-weight: 900;
  padding-top: 3rem;
  font-style: bold;
  margin-bottom: 3rem;
  text-align: center;
  letter-spacing: -0.5rem;
}

.category-col {
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  
}

.category-card,
.category-card .card-body {
  background: transparent !important;
  border: none !important;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.category-card img {
  height: 15rem;
  margin-bottom: 1rem;
}

.category-card .category-text {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1;
  position: relative;
  color: var(--black);
  text-decoration: none !important;
  height: 4.5rem;
  letter-spacing: -0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}

.category-card:hover .category-text {
  background: linear-gradient(to right, orange, gold);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background 0.3s ease-in-out;
  
}

.category-card:hover .category-image {
  transform: scale(1.3);
  transition: transform 0.5s ease-in-out;
}

.category-card .category-image {
  transition: transform 0.5s ease-in-out;
}

.category-link {
  text-decoration: none !important;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}


@media (max-width: 440px) {
  .home-categories {
    border-radius: 0;
    justify-content: center;
    align-items: center;
  }

  .category-line {
    margin-top: 0rem;
  }

  .home-categories h2 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 3.5rem;
    letter-spacing: -0.5rem;
    font-weight: 900;
    padding-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    margin-right: .5rem;
  }

  .category-card .category-text {
    font-size: 2rem;
    margin-top: -1.5rem;
  }

  .category-card img {
    height: 12rem;
    margin-bottom: 1rem;
  }

}

@media (min-width: 441px) and (max-width: 767px) {

  .beechler-arb-logo {
    margin-top: 15rem!important;
    margin-bottom: -5rem!important;
  }
  .category-line {
    margin-top: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1312px) {
  .beechler-arb-logo {
    margin-top: 13.375rem!important;
    margin-bottom: -5rem!important;
  }
  .category-line {
    margin-top: 2rem;
  }
}

@media (min-width: 1313px) {
  .beechler-arb-logo {
    margin-top: 10.25rem!important;
    margin-bottom: -5rem!important;
  }
  .category-line {
    margin-top: 2rem;
  }
}
