@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~react-responsive-carousel/lib/styles/carousel.min.css";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import url("./styles/theme.css");
@import url("./styles/NavBar.css");
@import url("./styles/HomeCarousel.css");
@import url("./styles/HomeTrio.css");
@import url("./styles/HomeAbout.css");
@import url("./styles/HomeContact.css");
@import url("./styles/ProductsPage.css");
@import url("./styles/ProductNav.css");
@import url("./styles/TipOpenings.css");
@import url("./styles/InstagramCarousel.css");


.App {
  width: 100%; 
  overflow-x: hidden;
}

.scroll-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
}

.scroll-to-top.visible {
  opacity: 0.8;
}

.scroll-to-top-button {
  background-color: rgb(0, 0, 0);
  color: #fff;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
}

.scroll-to-top-button:hover {
  opacity: 1;
}

.scroll-to-top-chevron {
  background: linear-gradient(to right, orange, gold);
  -webkit-background-clip: text;
  width: 2rem;
  height: 2rem;
}

