.our-commitment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 10rem;

}

.our-commitment-container h1 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: -2rem;
}

.our-commitment-container p {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  padding: 0 2rem;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.tonalex-gif {
  height: 10rem;
  display: block;
  margin: 0 auto;
  image-rendering: -webkit-optimize-contrast; 
  image-rendering: crisp-edges; 
}

@media screen and (max-width: 568px) {
  .our-commitment-container h1{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .our-commitment-container p {
    width: 30rem;
  }

}