.stripe-home-dealer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.stripe-home-dealer::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 200vw;
  left: -100vw;
  z-index: -1;
  background-color: var(--black);
  border: none;
}

.home-dealer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
  margin: 0 auto;
  border: none;
}

.home-dealer-title {
  font-size: 5rem;
  font-weight: 700;
  padding-top: 3rem;
  color: var(--white);
  font-family: 'Josefin Sans', sans-serif;
  text-align: center;
}

.home-dealer-map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -3rem;
  margin-bottom: -5rem;

}

.home-dealer-map {
  position: relative;
}


.reach-compliant-sticker{
  position: absolute;
  bottom: 0;
  right: 10rem;
}

.reach-compliant-sticker:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
  cursor: pointer;
}
@media screen  and (max-width: 500px){
  .home-dealer-map-container{
    margin-top: -1rem;
    margin-bottom: -1rem;

  }
  .reach-compliant-sticker {
    max-width: 150px!important;; 
    bottom: 1rem;
    right: 1rem;
  }
  .home-dealer-title {
    font-size: 4rem;
    letter-spacing: -0.3rem;
  }
}

@media only screen and (max-width: 768px) {
  .reach-compliant-sticker {
    width: 200px!important;; 
    bottom: 0;
    right: 1;
  }
}

/* Modal */
.react-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.react-modal-content {
  overflow: auto;
  max-width: 400px;
  background: no-repeat center center fixed; 
  padding: 20px;
  position: relative;
  border-radius: 15px; 
  text-align: center;
  margin: 0 auto; 
  background-size: cover;
  background-origin: border-box;
}

.modal-body {
  max-width: 100%;
  overflow: auto;
}

.react-modal-content .modal-body {
  background: rgba(255, 255, 255, 0.7); /* Translucent white background */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
}

/* Modal Header */
.react-modal-content h2 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 900;
  color: var(--black);
}

/* Modal Body */
.react-modal-content p {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: larger;
  color: var(--grey);
}

.close-button {
  /*button currently bottom left corner, should be centered*/
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 7, 7); 
  border: none; 
  color: white; /* text color */
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 500;
  cursor: pointer; /* change cursor to pointer when hovering over the button */
  border-radius: 5px;
  position: absolute;
  top: 94%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 712px) {


  .react-modal-content {
    overflow: auto;
    border-radius: 1rem!important;
  }

  .modal-body {
    max-width: 100%;
    overflow: auto;
  }

  .react-modal-content .modal-body {
    border-radius: 10px;
  }
}

