.tip-openings {
  font-family: Josefin Sans, sans-serif;
  color: var(--white);
  height: 100%;  
}

.tip-openings table {
  margin: 1rem auto;
  width: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border-collapse: collapse;
  overflow-x: auto;
}

.tip-openings th,
.tip-openings td {
  padding: 12px;
  text-align: center;
  border: none;
  outline: none;
}

.tip-openings th {
  background-color: var(--nav-accent);
  color: var(--white);
}

.tip-openings tbody tr:nth-child(even) {
  background-color: var(--grey);
  color: var(--white);
}

.tip-openings tbody tr:nth-child(odd) {
  background-color: var(--white);
  color: var(--black);
}

.tip-openings tfoot tr {
  background-color: var(--nav-accent);
  color: var(--white);

}

.tip-openings tfoot tr:hover td {
  cursor: pointer;
  background-color: lightgray;
}

.tip-openings td:hover,
.tip-openings td:focus {
  color: var(--nav-accent);
}

.big-table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (max-width: 440px) {
  .tip-openings {
    font-size: 0.875rem;  
  }

  .tip-openings th,
  .tip-openings td {
    padding: 0.5rem; 
  }
  
  .tip-openings table {
    border-radius: 5px;
  }


  .big-table {
    font-size: 0.875rem;
    margin-left: -1.25rem!important;;  
    
    
  }



}
