/* Color Palette */
:root {
--lt-grey: #6c757d;  
--grey: #2a3132;
--grey-alt: #343541;
--white: #fdfdfe;
--black: #0c0b0b;
--black-alt: #202123; 
--accent: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
--nav-accent: #fbb034
}


@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.super-title {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 900;
}

.header {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
}

.sub-header {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
}

.header-lite {
  font-family: 'Hind', sans-serif;
  font-weight: 700;
}

.paragraph {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
}

.caption {
  font-family: 'Hind', sans-serif;
  font-weight: 300;
}





/* Fonts */
@font-face {
  font-family: 'Big Shoulders Text';
  src: url('/src/assets/fonts/BigShouldersText-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Big Shoulders Text';
  src: url('/src/assets/fonts/BigShouldersText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Big Shoulders Text';
  src: url('/src/assets/fonts/BigShouldersText-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
