.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  padding: 2rem;
  color: var(--white);
  font-family: Josefin Sans, sans-serif;
}

.contact-form {
  background-color: var(--black-alt);
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
}

.contact-form h2  {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--white);
}

.contact-form h3 {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: gold;
  align-items: center;
  text-align: center;
}

.contact-form label,
.contact-form select {
  font-weight: 100;
  font-size: 1.2rem;
  display: block;
  margin-bottom: 0.5rem;
  color: var(--white);
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  width: 100%;
  padding: 0.5rem;
  font-weight: 100;
  font-size: 1rem;
  border: 1px solid var(--white);
  background-color: var(--grey-alt);
  color: var(--white);
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.contact-form button {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  background: var(--accent);
  color: var(--white);
  border-radius: 0.25rem;
  transition: 0.3s;
}

.contact-form button:hover {
  opacity: 0.8;
}

.contact-form .disclaimer {
  font-size: 0.8rem;
  color: gold;
  margin-top: 0.5rem;
  text-align: center;
}

.message-section {
  background-color: var(--black-alt);
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.message-section p {
  font-weight: 100;
  font-size: 1.2rem;
  color: var(--white);
}

