
.dynamic-info {
  display: flex;
  border-radius: 5px;
  padding: 1rem;
  font-family: Josefin Sans;
  font-size: 1.25rem;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dynamic-info h3 {
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.info-card {
  margin-top: 1rem;

}

.info-card .card-header, .bore-card .card-header{
  background-color: #4271d7 !important;
  color: #ffffff !important;
  font-weight: 700;
  padding: 0.75rem 1.25rem;
  text-align: center;
}

.info-card-text,
.bore-card-body, .card-header {
  text-align: center;
}


.info-card-body {
  color: #000000;
  font-weight: 400;
  text-align: center;
  padding: 0.75rem 1.25rem;
}

.bore-card {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.single-bore .info-row {
  flex-direction: row;
}

.bore-card-title {
  text-align: center;
  padding: 0.75rem 1.25rem;
  font-weight: 700;
  background-color: #3e4042 !important;
  color: #ffffff !important;
}

.bore-card-image {
  margin-top: 1rem;
  max-height: 5rem;
  object-fit: contain;
}


div.bore-and-info-row.justify-content-center.row > div > div > div {
  margin: 0 auto;
}

