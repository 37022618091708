.control-prev,
.control-next {
  display: none;
}

.home-about .stripe-home-about {
  overflow: visible;
  z-index: 0;
}

.our-story-carousel {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  z-index: 1;
  overflow: visible!important;
}

.our-story-slide {
  width: 100%;
  height: 100%;
  overflow: visible;
  transition: opacity 2s ease;
  z-index: 2;
}

.our-story-slide img {
  height: 30rem !important;
  width: 30rem !important;
  object-fit: cover;
  position: relative;
  border-radius: 1rem;
  transition: opacity 2s ease;
  z-index: 3;
}

.our-story-tag {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 900;
  font-size: 3.5rem;
  font-style: italic;
  background: var(--accent);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  animation: fadeInMoveRight 7.5s ease;
  transition: opacity 1s ease, transform 3s ease;
  line-height: 1.2;
  padding-right: 5rem !important;
  z-index: 4;
  overflow: visible!important;
}

.our-story-tag.fadeInMoveRight {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) translateX(50px);
}

.slide:not(.selected) .our-story-tag {
  animation: none;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) translateX(-50px);
  overflow: hidden;
}

.slide.selected .our-story-tag {
  animation: fadeInMoveRight 8s ease;
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) translateX(50px);
}

@keyframes fadeInMoveRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(50px);
  }
}

@media (max-width: 440px) {
  .our-story-slide img {
    height: 20rem !important;
    width: 25rem !important;
  }
  .our-story-tag {
    font-size: 2rem;
    line-height: 1;
    transform: translate3d(-50%, -50%, 0);
  }
  .our-story-carousel {
    margin-left: -2.25rem;
    width: 120%;
  }
}

@media screen and (min-width: 441px) and (max-width: 949px) {
  .our-story-carousel {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -2rem;
    width: 110%;
  }
}

@media (min-width: 401px) and (max-width: 712px) {
  .our-story-tag {
    font-size: 2rem;
    line-height: 1;
  }
}
