.topbar {
    background: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);
    color: var(--black);
    padding: 10px;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    z-index: 10;
  }
  
  @media (max-width: 768px) {
    .topbar {
      font-size: 1rem;
    }
  }
  