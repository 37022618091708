.stripe-home-testimonials {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: var(--black);
  
}

.stripe-home-testimonials::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 200vw;
  left: -100vw;
  z-index: -1;
  background-color: var(--black);
  border: none;
}

.home-testimonials {
    border: none;
  }
  
  .home-testimonials blockquote {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--white);
  }
  
  .home-testimonials footer {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 100;
    font-size: 1.5rem!important;
  }
  
  .testimonial-card {
    background-color: var(--grey-alt);
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
  }
    
  
  .testimonial-card footer {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 100;
    font-size: 1rem;
    color: #fff;
  }
  
  .testimonial-card .stars {
    display: flex;
    justify-content: center;
    margin-top: -.5rem!important;
  }
  
/*reduce testimonial card width on mobile*/

@media (max-width: 576px) {
  .testimonial-card {
    width: 90%!important;

  }
}
