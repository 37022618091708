.stripe-home-about {
  position: relative;
}

.stripe-home-about::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 200vw;
  left: -100vw;
  z-index: -1;
  background-color: var(--black);
}

.home-about {
  background-color: var(--black);
  font-family: "Josefin Sans", sans-serif;
}

.home-about-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-about-left {
  text-align: center;
}

.home-about-left h2 {
  font-size: 5rem;
  font-weight: 700;
  background: var(--accent);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

}

.home-about-left p {
  align-self: flex;
  font-weight: 300;
  font-size: 2rem;
  color: var(--white);
  margin-top: 1rem;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

}

/*esnure the our story text tittle and text below is larger on mobile, header equivalent to 5rem and text below pproportionally smaller */
@media (max-width: 768px) {
  .home-about-left h2 {
    font-size: 4rem;
  }

  .home-about-left p {
    font-size: 1.5rem;
  }
}