.masonry-grid {
  display: flex;
  margin-left: -30px;
  width: auto;
}

.masonry-grid_column {
  padding-top: 5px;
  padding-left: 10px;
  background-clip: padding-box;
}

.masonry-grid_column > img {
  margin-bottom: 10px;
  width: 100%;
}

/*align to center of page*/
.gallery-title-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
  padding: 2rem;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-top: .5rem!important;
}

.gallery-title {
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  margin-left: auto!important;
  margin-right: auto!important;
}

.swing-in-top-fwd {
  animation: swing-in-top-fwd 2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation-delay: var(--animation-delay, 0s);
  will-change: transform, opacity;
}

.swing-in-top-fwd.ready-to-animate {
  will-change: transform, opacity;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-16 13:7:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .masonry-grid {
    width: 100%;
    margin-left: 0px;
  }
  .masonry-grid_column {
    padding-left: 5px;
    padding-right: 5px;
  }
  .gallery-title-card {
    padding: 1rem;
    margin-left: -1rem!important;
    width: 110%;
  }

  .gallery-title {
    font-size: 1.5rem;
  }
}
