.not-found {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Josefin Sans', sans-serif;
  color: #202123;
  width: 100%;
  margin: 0;
  padding: 0;
}

.jazz-gif {
  border-radius: 1rem;

}

.not-found h2{
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: -5rem;
}
.not-found h1 {
  font-size: 6rem;
  margin-bottom: 1rem;
}

.not-found p {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.not-found-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.not-found-buttons button {
  background-color: #38393c; /* change this line */
  color: #ffffff;
  font-size: 2rem;
  font-weight: 100;
  padding: 1rem 2rem;
  border: none;
  border-radius: 1rem;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.not-found-buttons button:hover {
  box-shadow: 0px 0px 20px 2px rgba(255, 174, 0, 0.7);
}

.not-found-buttons button svg {
  width: 2rem;
  height: auto;
  margin-right: 1rem;
}

/* media query to make the buttons smaller on mobile */

@media screen and (max-width: 480px) {
  .not-found-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .not-found-buttons button {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }

  .not-found-buttons button svg {
    width: 1.5rem;
    margin-right: 0.5rem;
  }
}