
.navbar {
  background-color: var(--black-alt);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.navbar-brand img {
  height: 5rem;
  width: auto;
  margin-left: 1rem;
}

.nav-link {
  font-family: Josefin Sans, sans-serif;
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 150px;
}

.nav-link.spacing {
  margin-right: 10px;
}

.navbar-tip-openings {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-tip-openings span {
  line-height: 1;
}

.nav-link:hover,
.active-link {
  background-image: var(--accent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-button {
  margin: 0.5rem 1rem 0 0;
}

.hamburger {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.hamburger:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.hamburger .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  margin: 4px 0;
  transition: transform 0.7s ease-in-out;
  background-color: #fbb034;
}
.hamburger .top-bar,
.hamburger .middle-bar,
.hamburger .bottom-bar {
  transform-origin: top left;
  background-color: #fbb034;
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.7s;
}
.hamburger .bottom-bar {
  transform-origin: bottom left;
}
.hamburger .icon-bar.top-bar.toggled {
  transform: translateY(50%) translateY(-4px) rotate(45deg);
}
.hamburger .icon-bar.middle-bar.toggled {
  opacity: 0;
}
.hamburger .icon-bar.bottom-bar.toggled {
  transform: translateY(-50%) translateY(1px) rotate(-45deg);
}

/* Mobile */
@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
    align-items: flex-end;
  }
  div .navbar-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-link-wrapper {
    opacity: 0;
    transition: opacity 0.5s ease-in-out 0.1s !important;
  }

  .navbar.expanded .nav-link-wrapper {
    opacity: 1;
  }

  .navbar-tip-openings {
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar-tip-openings span {
    line-height: normal;
    margin-right: 7.5px;
  }

  .nav-link,
  .contact-button {
    margin-left: auto;
    justify-content: flex-end;
    margin-right: 1rem;
  }
}
/* Tablet */
@media (min-width: 768px) and (max-width: 992px) {
  .nav-link-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: space-evenly;
  }

  .nav-link {
    font-size: 0.9rem;
  }
  .contact-button {
    margin-right: 4rem;
    margin-bottom: 0.5rem;
  }
}
/* Laptop + Desktop */
@media (min-width: 993px) and (max-width: 1312px) {
  .nav-link-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%;
    justify-content: space-evenly;
  }
  .contact-button {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
}
