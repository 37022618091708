/* Global styles */
.about-page {
  font-family: "Josefin Sans", sans-serif;
}

/* Top section */
.about-header {
  padding: 4rem;
}

.about-header h1 {
  font-size: 5rem;
  font-weight: 700;
  color: #000000;
}

.intro-letter::first-letter {
  font-size: 2em;
  font-weight: bold;
  color: #fbb035;
  margin: 0.1em;
}

.about-header-text {
  margin-top: 2rem;
  font-size: 1.75rem;
  font-weight: 300;
  margin-left: -5rem;
}

.elmer-about-container {
  float: right;
  width: 30rem;
  margin-right: -29rem;
  margin-top: 5rem;
}

.elmer-tag {
  font-size: 2.5rem !important;
  font-weight: 300;
  color: #000000;
  margin-left: 7rem;
  margin-top: -1rem;
}

.wave-about {
  width: 100%; 
  height: auto; 
  margin-top: -35rem;
  margin-bottom: -50rem;
  z-index: -1;
}

/* Horizontal scrolling timeline */
.carousel-container {
  text-align: center;
}

.dot {
  height: 0.5rem;
  width: 0.5rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.25rem;
  transition: background-color 0.6s ease;
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

img.about-carousel-img {
  width: 50rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
  margin: auto;
  margin-bottom: 2rem;
}

p.about-carousel-text {
  max-width: 50rem;
  font-size: 1.5rem;
  font-weight: 300;
  color: #000000;
}

/* Footer text and video */
.about-footer {
  margin-top: 2rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
}

.about-footer-text-container {
  margin-top: -3rem;
  text-align: center;
  align-self: center;
}

.about-footer h1 {
  font-size: 4rem;
  font-weight: 700;
  color: #000000;
  letter-spacing: -7px;
  margin-top: 5rem;
}

.about-text2 {
  margin-top: 2rem;
  font-size: 2.5rem;
}

div .about-video {
  width: 20rem;
  height: auto;
  border-radius: 1rem;
  margin-top: 3rem;
}

/* Media queries */
@media only screen and (max-width: 400px) {
  /* Top section */
  .about-header h1 {
    margin-top: -2rem;
    letter-spacing: .5px;
  }

  div.elmer-about-container.float-right > figure > img {
    width: 15rem;
  }

  .elmer-tag {
    font-size: 1.5rem !important;
    margin-left: 0;
    margin-top: .5rem;
  }

  .intro-letter {
    text-align: center;
    width: 175%;
    margin-left: -32.5%;
  }

  .wave-about {
    width: 100%; 
    height: auto; 
    margin-top: -5rem;
    margin-bottom: -15rem;
    margin-left: -2rem;
    z-index: -1;
  }

  .treble2 {
    z-index: 1;  
  }

  /* Footer text and video */
  .about-footer h1 {
    font-size: 2rem;
    letter-spacing: .5px;
  }

  img.about-carousel-img {
    max-width: 20rem;
  }

  p.about-carousel-text {
    max-width: 20rem;
  }

  div .about-video {
    max-width: 15rem;
  }
}

@media only screen and (max-width: 768px) {
  /* Top section */
  .about-header,
  .elmer-about-container,
  .about-footer {
    text-align: center;
    margin: auto;
  }

  .about-header h1,
  .about-footer h1 {
    font-size: 3rem;
  }

  .about-header-text,
  .elmer-tag,
  .about-text2 {
    font-size: 1.5rem;
    margin-left: 0;
  }

  .elmer-about-container {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.elmer-about-container.float-right > figure > img {
    width: 15rem;
  }

  .intro-letter {
    text-align: center;
    width: 175%;
    margin-left: -32.5%;
  }

  .wave-about {
    width: 250%; 
    height: auto; 
    margin-top: -5rem;
    margin-bottom: -15rem;
    margin-left: -5rem;
    z-index: -1;
  }

  img.about-carousel-img {
    width: 100%;
    max-width: 30rem;
  }

  p.about-carousel-text {
    max-width: 30rem;
    margin: auto;
  }

  div .about-video {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 730px) and (max-width: 900px) {
  /* Top section */
  .about-header-text,
  .elmer-about-container {
    margin-left: 2rem;
    margin-right: -5rem;
  }

  .about-header-text {
    margin-top: 25rem;
    margin-left: 5rem;
  }
}